
import { Options, Vue } from 'vue-class-component';
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import {
  AccountList,
  ActionModal,
  Address as AddressAutocomplete,
  BaseButton,
  BaseDatePicker,
  BaseIcon,
  BaseModal,
  BaseSelect,
  BaseSelectDropdown,
  BaseTextarea,
  BaseTextInput,
  LegalPolicy,
  PhoneNumberInput,
  SelectInput
} from '@/lib/components';
import {
  Address,
  Ethnicity,
  ExternalPatientReference,
  ExternalPatientReferenceType,
  PaginatedResponse,
  Patient
} from '@/models';
import { EthnicityService, OrganisationPatientService } from '@/services/api';
import { IAccount, IModalAction, IOption } from '@/lib';
import { verifyMrn } from '@/helpers/patient.helper';
import { isValidEmail } from '@/helpers/strings.helper';
import { formattedNHSNumber, isNHSNumberValid } from '@/lib/helpers/nhs-number.helper';
import { FEATURES, genderOptions, sexOptions } from '@/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { Pathways, recordUserEvent } from '@/helpers/aws.helper';
import { MRN_MAX_LENGTH } from '@/constants/patients';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    SelectInput,
    BaseIcon,
    AddressAutocomplete,
    BaseDatePicker,
    BaseTextarea,
    BaseModal,
    ActionModal,
    AccountList,
    BaseButton,
    BaseTextInput,
    BaseSelect,
    PhoneNumberInput,
    WorkflowLayout,
    LegalPolicy,
    BaseSelectDropdown
  }
})
export default class NewPatientPage extends Vue {
  sessionStore = useSessionStore();
  patientStore = usePatientStore();
  notificationStore = useNotificationStore();
  sexOptions = sexOptions();
  genderOptions = genderOptions();
  saving = false;
  steps: string[] = []; // TODO 'Privacy Policy' should be shown by `implied_patient_consent`, just remove for UHB DEMO
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  sex = '';
  gender = '';
  address: Address = {
    formatted_address: ''
  };

  mrn: IAccount | null = null;
  contactNumber = '';
  emailAddress = '';
  ethnicity = '';
  acceptTerms = true;
  consentToMedicalResearch = false;
  policyFetched = true;
  errors: { [key: string]: string[] } = {};
  ethnicities: Array<Ethnicity> = [];
  externalPatientReferences: Array<ExternalPatientReference> = [];
  accounts: Array<IAccount> = [];
  showAccountModal = false;
  showMRNAccountModal = false;
  selectedExternalReferenceTypeId = '';
  referenceId = '';

  ethnicityService = new EthnicityService();
  request: CancelTokenSource | null = null;
  $customer?: string;

  nhsNumberLabel = 'uhb-nhs-number';
  isPatientPhoneOptional = !isFeatureFlagEnabled(FEATURES.PATIENT_PHONE_MANDATORY);
  isPatientAddressOptional = isFeatureFlagEnabled(FEATURES.PATIENT_ADDRESS_OPTIONAL);
  isPatientMRNLimited = isFeatureFlagEnabled(FEATURES.PATIENT_MRN_LIMIT);

  isPatientEmailOptional = !isFeatureFlagEnabled(FEATURES.PATIENT_EMAIL_MANDATORY);

  selectedRegionId: string | null = null;
  patient: Patient | null = null;

  get mrnInputCount(): number | null {
    return this.isPatientMRNLimited && this.showMRNAccountModal ? MRN_MAX_LENGTH : null;
  }

  get modalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('platform.patient.continue-to-patient'),
        onClick: () => this.$router.push(`/patients/${this.patient?.id}`)
      },
      {
        color: 'secondary',
        label: this.$t('platform.patient.add-another'),
        onClick: () => this.reset()
      },
      {
        color: 'ghost',
        label: this.$t('platform.common.close'),
        onClick: () => {
          this.$router.push({ name: 'patient-list' });
        }
      }
    ];
  }

  mounted() {
    recordUserEvent('started creating a new patient', Pathways.PLATFORM);
  }

  get allLegalRegions(): string[] {
    return this.sessionStore.legalRegions;
  }

  get branding() {
    return this.sessionStore.customerBranding;
  }

  get legalregionsDropdownOptions() {
    return this.allLegalRegions.map((region) => ({
      value: region,
      label: this.$t(`platform.countries.${region.toUpperCase()}`)
    }));
  }

  get isAccountItemListButtonHidden(): boolean {
    return this.accounts.every((a) => a.value) && this.accounts.length === this.externalPatientReferenceTypes.length;
  }

  get selectedExternalReferenceTypeLabel(): string {
    const externalReferenceType = this.getExternalReferenceType(this.selectedExternalReferenceTypeId);

    if (externalReferenceType) {
      return externalReferenceType.label;
    }
    return '';
  }

  get disableSaveChangesOnAccountModal(): boolean {
    if (this.showMRNAccountModal) {
      return !this.referenceId.length || !!this.errors?.account?.length;
    }

    if (this.selectedExternalReferenceTypeLabel === this.nhsNumberLabel) {
      return !isNHSNumberValid(this.referenceId);
    }
    return !this.selectedExternalReferenceTypeId.length || !this.referenceId.length;
  }

  get hasValidMrn(): boolean {
    return this.mrnExists && (!this.errors.account || !this.errors.account.length);
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get minDate() {
    return dayjs().subtract(150, 'year').format('YYYY-MM-DD');
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get ethnicityOptions(): IOption[] {
    return this.ethnicities.map((ethnicity) => ({
      value: ethnicity.id,
      label: ethnicity.title
    }));
  }

  get usesGender() {
    return this.sessionStore.currentOrganisation?.uses_gender;
  }

  get externalPatientReferenceTypes(): Array<ExternalPatientReferenceType> {
    return this.patientStore.externalPatientReferenceTypes;
  }

  get externalPatientReferenceTypeOptions() {
    return this.externalPatientReferenceTypes
      .filter(
        (externalPatientReferenceType) =>
          !this.accounts.find((account) => account.value && account.typeId === externalPatientReferenceType.id)
      )
      .map((externalPatientReferenceType) => ({
        value: externalPatientReferenceType.id,
        label:
          externalPatientReferenceType.label === this.nhsNumberLabel
            ? this.$t('custom.uhb.patient.nhs-number')
            : externalPatientReferenceType.label
      }));
  }

  get primaryExternalPatientReferenceType(): ExternalPatientReferenceType {
    // FIXME: Not sure if this is the right method to do this long term
    return {
      id: 'mrn',
      key: 'mrn',
      label: this.$t('platform.patient.mrn') as string
    };
  }

  get step() {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get disableNext() {
    return (
      (this.step === 0 && (!this.checkForm() || !this.mrnExists)) ||
      (this.step === 1 && (!this.acceptTerms || !this.policyFetched))
    );
  }

  get errorsCount() {
    return Object.values(this.errors).filter((value) => value.length).length;
  }

  get checkPatientEmailFeature() {
    if (this.isPatientEmailOptional) {
      return true;
    }
    return this.emailAddress && this.emailAddress.length;
  }

  get checkPatientContactNumberFeature() {
    if (this.isPatientPhoneOptional) {
      return true;
    }
    return this.contactNumber && this.contactNumber.length;
  }

  get checkPatientAddress(): boolean {
    if (this.isPatientAddressOptional) {
      return true;
    }
    return !!this.address.formatted_address?.length;
  }

  get orgPatientService() {
    return new OrganisationPatientService(this.organisationId);
  }

  checkForm() {
    return (
      this.firstName.length &&
      this.lastName.length &&
      this.dateOfBirth.length &&
      this.checkPatientEmailFeature &&
      (this.sex.length || this.gender.length) &&
      this.checkPatientContactNumberFeature &&
      this.checkPatientAddress &&
      this.errorsCount === 0 &&
      (this.$refs.phone as PhoneNumberInput).isValid
    );
  }

  get mrnExists(): boolean {
    return Boolean(this.mrn && this.mrn.value && this.mrn.value.length);
  }

  beforeCreate() {
    this.steps = [this.$t('platform.patient.details') as string];
  }

  async created() {
    this.selectedRegionId = this.allLegalRegions[0];
    // Make sure we always start on the first step when navigating to this page
    if (typeof this.$route.query.step !== 'undefined') {
      this.$router.replace(this.$route.path);
    }
    try {
      this.request = axios.CancelToken.source();
      this.ethnicities = await this.fetchEthnicities(this.request.token);
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data') as string
        });
      }
    } finally {
      this.request = null;
    }

    if (this.$customer === 'MENICON') {
      this.steps = [
        this.$t('platform.patient.details') as string,
        this.$t('platform.legal.privacy-policy.title') as string
      ];
      this.acceptTerms = false;
      this.policyFetched = false;
    }
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  onEmailChange(value: string) {
    if (this.errors.email && this.errors.email.length) {
      this.validateEmail(value);
    }
  }

  validateDate(date: string) {
    if (dayjs(date).isAfter(dayjs())) {
      this.errors = {
        ...this.errors,
        date_of_birth: [this.$t('platform.patient.dob-error', [this.today]) as string]
      };
    } else if (this.errors.date_of_birth) {
      this.errors = {
        ...this.errors,
        date_of_birth: []
      };
    }
  }

  validateName(ev: KeyboardEvent) {
    const isValid = /^[a-z ,.'-]+$/i.test(ev.key);
    if (!isValid) {
      ev.preventDefault();
    }
    return isValid;
  }

  validateEmail(email: string) {
    if (email !== '' && !isValidEmail(email)) {
      this.errors = {
        ...this.errors,
        email: [this.$t('platform.error.email-error') as string]
      };
    } else if (this.errors.email) {
      this.errors = {
        ...this.errors,
        email: []
      };
    }
  }

  validateNHSNumberOnBlur() {
    if (this.referenceId) {
      this.referenceId = formattedNHSNumber(this.referenceId);
    }
    this.validateNHSNumber();
  }

  validateNHSNumber() {
    if (this.referenceId && !isNHSNumberValid(this.referenceId)) {
      this.errors = {
        ...this.errors,
        referenceId: [this.$t('custom.uhb.patient.nhs-number-error') as string]
      };
    } else if (this.errors.referenceId) {
      this.errors = {
        ...this.errors,
        referenceId: []
      };
    }
  }

  async fetchEthnicities(cancelToken: CancelToken) {
    return (
      await this.ethnicityService.index({
        cancelToken
      })
    ).data;
  }

  getExternalReferenceType(id: string) {
    return this.externalPatientReferenceTypes.find((reference) => reference.id === id);
  }

  closeAccountModal() {
    this.showAccountModal = false;
    this.showMRNAccountModal = false;
    this.selectedExternalReferenceTypeId = '';
    this.referenceId = '';
    this.clearAccountError();
  }

  async saveAccountModal() {
    try {
      if (this.referenceId && this.referenceId.length) {
        const referenceType = this.showMRNAccountModal
          ? this.primaryExternalPatientReferenceType
          : this.getExternalReferenceType(this.selectedExternalReferenceTypeId);
        if (referenceType) {
          const account: IAccount = {
            label: referenceType.label,
            typeId: referenceType.id,
            value: this.referenceId
          };
          if (this.showMRNAccountModal) {
            await this.validateMrn(account.value ? account.value : '');
            this.mrn = account;
          } else {
            const index = this.accounts.findIndex((a) => a.typeId === account.typeId);
            const accounts = [...this.accounts];
            if (index >= 0) {
              accounts[index] = account;
            } else {
              accounts.push(account);
            }
            this.accounts = accounts;
          }
        }
      }
      this.closeAccountModal();
    } catch (error) {
      this.errors = {
        ...this.errors,
        account: [error.message]
      };
    }
  }

  editAccount(account: IAccount) {
    this.selectedExternalReferenceTypeId = account.typeId;
    this.referenceId = account.value ? account.value : '';
    this.primaryExternalPatientReferenceType.id === account.typeId
      ? (this.showMRNAccountModal = true)
      : (this.showAccountModal = true);
  }

  deleteAccount(account: IAccount) {
    if (account.typeId === this.primaryExternalPatientReferenceType.id) {
      this.mrn = null;
    } else {
      this.accounts = this.accounts.map((a) => {
        if (a.typeId === account.typeId) {
          a.value = null;
        }
        return a;
      });
    }
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }

    this.$router.go(-1);
  }

  next() {
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        step: String(this.step + 2) // Add 2 because the URL param is 1-based
      }
    });
  }

  reset() {
    // FIXME
    this.$router.go(0);
  }

  async complete() {
    this.saving = true;

    try {
      if (this.accounts.length) {
        this.externalPatientReferences = this.accounts.map((account) => ({
          external_patient_reference_type_id: account.typeId,
          value: account.value
        }));
      }

      const newPatientData: Partial<Patient> = {
        first_name: this.firstName,
        last_name: this.lastName,
        date_of_birth: this.dateOfBirth,
        sex: this.sex,
        gender: this.gender,
        contact_number: this.contactNumber,
        email: this.emailAddress,
        address: this.address,
        accept_terms: this.acceptTerms,
        consent_to_medical_research: this.consentToMedicalResearch,
        external_patient_references: this.externalPatientReferences
      };

      if (this.ethnicity) {
        const selectedEthnicity = this.ethnicities.find(({ id }) => id === this.ethnicity);
        if (selectedEthnicity) {
          newPatientData.ethnicity_id = selectedEthnicity.id;
        }
      }
      if (this.mrn) {
        newPatientData.mrn = this.mrn.value as string;
      }
      this.patient = (await this.orgPatientService.create(newPatientData)).data;

      recordUserEvent('completed creating a new patient', Pathways.PLATFORM, this.patient.id);
      this.next();
    } catch (error) {
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.saving = false;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.patient.saving-error'),
          label: error.response.data.message
        });
      }
      // TODO: Handle other errors?
      this.saving = false;
      console.error(error);
      return;
    }
  }

  cancel() {
    this.$router.push('/patients');
  }

  async validateMrn(mrn: string) {
    if (!verifyMrn(mrn)) {
      throw new Error(this.$t('custom.uhb.consult.spaces-not-allowed') as string);
    }
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'patient',
        'filter[mrn]': mrn,
        'filter[trashed]': 'with'
      },
      cancelToken: this.request.token
    };
    const response = (await this.orgPatientService.index(requestConfig)) as PaginatedResponse<Patient[]>;

    if (response.data.length) {
      throw new Error(this.$t('platform.patient.mrn-error') as string);
    }

    this.clearAccountError();
  }

  clearAccountError() {
    if (this.errors.account && this.errors.account.length) {
      this.errors = {
        ...this.errors,
        account: []
      };
    }
  }

  onReferenceIdInput(referenceId: string) {
    if (this.showMRNAccountModal) {
      if ((referenceId.length > MRN_MAX_LENGTH && this.isPatientMRNLimited)) {
        this.errors = {
          ...this.errors,
          account: [this.$t('platform.domain-admin.description-error', [MRN_MAX_LENGTH]) as string]
        };
      } else {
        this.errors = {
          ...this.errors,
          account: []
        };
      }
    }
  }

}
